/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { footersArray } from "../../utils/seeds/headersArray";

const Footer = () => {
  let navigate = useNavigate();

  function handleNavigate(path: string) {
    navigate(path);
  }

  return (
    <footer className="row rounded shadow-sm bg-body border-top w-100 align-items-center px-4 py-3">
      <p className="col-4 text-start text-muted m-0">© 2024 Company, Inc</p>
      <ul className="col-8 nav justify-content-end">
        {footersArray?.map((items, index) => {
          return (
            <li
              className="nav-item"
              onClick={() => handleNavigate(items.path)}
              key={index}
            >
              <a className="nav-link px-5 text-muted">{items?.name}</a>
            </li>
          );
        })}
      </ul>
    </footer>
  );
};

export default Footer;
