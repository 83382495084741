import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  mutateDeleteFn,
  mutatePostFn,
  QueryReturnType,
} from "../services/networking.service";
import ENDPOINTS from "../services/endPoints";
import { Auth } from "../../utils/interface";

interface IProps {
  data: FormData;
}

interface IDocsProps {
  limit: number;
  page: number;
}

// Custom hook for document uploading
export const useUploadDocument = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, IProps>(
    (data) => mutatePostFn(ENDPOINTS.UPLOAD_DOUCMENTS, data.data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

// Custom hook for documents list
export const useGetDocsList = (): QueryReturnType<any> => {
  return useQuery<never, never, any>(ENDPOINTS.GET_DOUCMENT_LIST, {
    notifyOnChangeProps: [
      "data",
      "error",
      "refetch",
      "isRefetching",
      "isSuccess",
      "isLoading",
    ],
  });
};

// Custom hook for delete doc
export const useDeleteDoc = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutateDeleteFn(ENDPOINTS.DELETE_DOUCMENT, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
