import ChangePassword from "../pages/auth/fogotPassword/changePassword";
import VerifyEmail from "../pages/auth/fogotPassword/emailVerify";
import VerifyOtp from "../pages/auth/fogotPassword/otpVerify";
import Login from "../pages/auth/login/Login";
import SignUp from "../pages/auth/signup/Signup";
import ContactUs from "../pages/screens/contactUs";
import DocumentManagement from "../pages/screens/documentManagement";
import PrivacyAndPolicy from "../pages/screens/privacyAndPolicy";
import CreateProfile from "../pages/screens/profile/createProfile";
import EditProfileDetail from "../pages/screens/profile/editProfile";
import ProfileDetail from "../pages/screens/profile/profileDetail";
import RequestManagement from "../pages/screens/requestManagement";
import SubscriptionPlan from "../pages/screens/subscriptionPlans";
import CancelTransaction from "../pages/screens/subscriptionPlans/cancelTransaction";
import SuccessTransaction from "../pages/screens/subscriptionPlans/successTransaction";
import TermsAndConditions from "../pages/screens/termsAndConditions";

export const RoutePath = {
  login: {
    name: "Login",
    component: <Login />,
    path: "/login",
    type: "public",
  },
  verifyEmail: {
    name: "Verify Email",
    component: <VerifyEmail />,
    path: "/verifyemail",
    type: "public",
  },
  verifyOtp: {
    name: "Verify Otp",
    component: <VerifyOtp />,
    path: "/verifyotp",
    type: "public",
  },
  changePassword: {
    name: "Change Password",
    component: <ChangePassword />,
    path: "/changepassword",
    type: "public",
  },
  signUp: {
    name: "Sign Up",
    component: <SignUp />,
    path: "/signUp",
    type: "public",
  },
  createProfile: {
    name: "Create Profile",
    component: <CreateProfile />,
    path: "/createprofile",
    type: "public",
  },
  profileDetail: {
    name: "Profile",
    component: <ProfileDetail />,
    path: "/profile",
    type: "private",
  },
  editProfileDetail: {
    name: "Edit Profile Detail",
    component: <EditProfileDetail />,
    path: "/editprofiledetail",
    type: "private",
  },
  subscriptionPlan: {
    name: "Subscription Plan",
    component: <SubscriptionPlan />,
    path: "/subscriptionplans",
    type: "private",
  },
  success: {
    name: "Success",
    component: <SuccessTransaction />,
    path: "/success",
    type: "private",
  },
  cancelTransaction: {
    name: "Cancel Transaction",
    component: <CancelTransaction />,
    path: "/canceltransaction",
    type: "private",
  },
  requestManagement: {
    name: "Request Management",
    component: <RequestManagement />,
    path: "/requestmanagement",
    type: "private",
  },
  documentManagement: {
    name: "Document Management",
    component: <DocumentManagement />,
    path: "/documentmanagement",
    type: "private",
  },
  contactUs: {
    name: "Contact Us",
    component: <ContactUs />,
    path: "/contactus",
    type: "private",
  },
  termsAndConditions: {
    name: "Terms And Conditions",
    component: <TermsAndConditions />,
    path: "/termsandconditons",
    type: "private",
  },
  privacyAndPolicy: {
    name: "Privacy And Policy",
    component: <PrivacyAndPolicy />,
    path: "/privacyandpolicy",
    type: "private",
  },
};
