import { RoutePath } from "../../routes/routePath";

export const headersArray = [
  {
    name: RoutePath.subscriptionPlan.name,
    path: RoutePath.subscriptionPlan.path,
  },
  {
    name: RoutePath.requestManagement.name,
    path: RoutePath.requestManagement.path,
  },
  {
    name: RoutePath.documentManagement.name,
    path: RoutePath.documentManagement.path,
  },
];

export const footersArray = [
  {
    name: RoutePath.contactUs.name,
    path: RoutePath.contactUs.path,
  },
  {
    name: RoutePath.termsAndConditions.name,
    path: RoutePath.termsAndConditions.path,
  },
  {
    name: RoutePath.privacyAndPolicy.name,
    path: RoutePath.privacyAndPolicy.path,
  },
];
