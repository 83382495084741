/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { toast } from "react-custom-alert";
import Swal from "sweetalert2";
import CustomAlert from "../../../components/common/alert";
import TableList from "../../../components/screens/requestsManagement/tableList";
import {
  useAcceptRequest,
  useDeleteRequest,
  useGetRequests,
  useRejectRequest,
} from "../../../react-query/hooks/requests";
import { tabsArray } from "../../../utils/seeds";

const RequestManagement = () => {
  // State to manage the active tab
  const [activeTab, setActiveTab] = React.useState(0);

  // State to manage the pagination
  const [pageNumber, setPageNumber] = React.useState(1);

  // Number of records per page
  const [count, setCount] = React.useState(5);

  // Fetch request data based on the current page, count, and active tab (status)
  const { data, isFetching } = useGetRequests({
    limit: count,
    page: pageNumber,
    status: activeTab,
  });

  // Mutations for accepting, rejecting, and deleting requests
  const { mutateAsync: acceptRequestMutateAsync, isSuccess: acceptIsSuccess } =
    useAcceptRequest();
  const { mutateAsync: rejectRequestMutateAsync, isSuccess: rejectIsSuccess } =
    useRejectRequest();
  const { mutateAsync: deleteRequestMutateAsync, isSuccess: deleteIsSuccess } =
    useDeleteRequest();

  // Handle accept request action with confirmation
  async function handleRequestAccept(id: string) {
    CustomAlert({
      title: " Accept the request?",
      confirmButtonText: "Accept",
      icon: "question",
      onConfirm: async () => {
        try {
          await acceptRequestMutateAsync({
            id: id,
          });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  // Handle reject request action with confirmation
  async function handleRequestReject(id: string) {
    CustomAlert({
      title: " Reject the request?",
      confirmButtonText: "Reject",
      icon: "question",
      onConfirm: async () => {
        try {
          await rejectRequestMutateAsync({
            id: id,
          });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  // Handle delete request action with confirmation
  async function handleRequestDelete(id: string) {
    CustomAlert({
      title: " Are you sure want to delete the request?",
      confirmButtonText: "Delete",
      icon: "question",
      onConfirm: async () => {
        try {
          await deleteRequestMutateAsync({
            id: id,
          });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  // Update page number for pagination
  function handlePageChange(newPage: number) {
    setPageNumber(newPage);
  }

  // Memoize the rendered request list to optimize re-rendering
  const renderRequests = useMemo(() => {
    return (
      <TableList
        isFetching={isFetching}
        totalRecords={data?.data?.totalRecords}
        count={count}
        pageNumber={pageNumber}
        handleRequestDelete={(id) => handleRequestDelete(id)}
        handleRequestReject={(id) => handleRequestReject(id)}
        handleRequestAccept={(id) => handleRequestAccept(id)}
        data={data?.data?.requestList}
        onPageChange={handlePageChange}
      />
    );
  }, [count, data, pageNumber, isFetching]);

  // Render tabs based on predefined list
  function renderTabsList() {
    return tabsArray?.map((tab, index) => (
      <button
        key={index}
        className={activeTab === tab.id ? "active" : ""}
        onClick={() => {
          setActiveTab(tab.id);
        }}
      >
        {tab.label}
      </button>
    ));
  }

  // Show success toast when a request is successfully rejected
  React.useEffect(() => {
    if (rejectIsSuccess === true) {
      toast.success("Request rejected");
      setActiveTab(2);
    }
  }, [rejectIsSuccess]);

  // Show success toast when a request is successfully deleted
  React.useEffect(() => {
    if (deleteIsSuccess === true) {
      toast.success("Request deleted");
    }
  }, [deleteIsSuccess]);

  // Show success toast when a request is successfully accepted
  React.useEffect(() => {
    if (acceptIsSuccess === true) {
      toast.success("Request accepted");
      setActiveTab(1);
    }
  }, [acceptIsSuccess]);

  return (
    <div className="container">
      <div className="row mt-5 g-4">
        <div className="col-12">
          <h1 className="company-title-txt">Request Management</h1>
          <div className="tabs-blk">
            <div className="tabs-inner">{renderTabsList()}</div>
          </div>
          {renderRequests}
        </div>
      </div>
    </div>
  );
};

export default RequestManagement;
