import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
import { RoutePath } from "./routePath";
import { useAppContext } from "../context/authContext";
import Header from "../components/common/header";
import PageNotFound from "../components/common/pageNotFound";
import Footer from "../components/common/footer";
import { globalConfig } from "../utils/constants/globalConfig";

const RouteStack = () => {
  const { isAuthenticated } = useAppContext();

  const isSubscribed = JSON.parse(
    localStorage.getItem(globalConfig.CARDIFY_LOGIN_DETAIL) || "null"
  );
  console.log();
  function PublicRoute() {
    if (isAuthenticated) {
      return (
        <Navigate
          to={
            isSubscribed?.isAnyPlanSubscribed
              ? RoutePath.requestManagement.path
              : RoutePath.subscriptionPlan.path
          }
          replace
        />
      );
    }
    return <Outlet />;
  }

  function PrivateRoute() {
    let location = useLocation();
    const showHeader =
      location.pathname !== RoutePath.success.path &&
      location.pathname !== RoutePath.cancelTransaction.path;
    const showFooter =
      location.pathname !== RoutePath.success.path &&
      location.pathname !== RoutePath.cancelTransaction.path;

    if (!isAuthenticated) {
      return <Navigate to={RoutePath.login.path} replace />;
    }

    return (
      <>
        {showHeader && <Header />}
        <Outlet />
        {showFooter && <Footer />}
      </>
    );
  }

  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route index element={RoutePath.login.component} />
          <Route
            path={RoutePath.login.path}
            element={RoutePath.login.component}
          />
          <Route
            path={RoutePath.verifyEmail.path}
            element={RoutePath.verifyEmail.component}
          />
          <Route
            path={RoutePath.verifyOtp.path}
            element={RoutePath.verifyOtp.component}
          />
          <Route
            path={RoutePath.changePassword.path}
            element={RoutePath.changePassword.component}
          />
          <Route
            path={RoutePath.signUp.path}
            element={RoutePath.signUp.component}
          />
          <Route
            path={RoutePath.createProfile.path}
            element={RoutePath.createProfile.component}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path={RoutePath.subscriptionPlan.path}
            element={RoutePath.subscriptionPlan.component}
          />
          <Route
            path={RoutePath.success.path}
            element={RoutePath.success.component}
          />
          <Route
            path={RoutePath.cancelTransaction.path}
            element={RoutePath.cancelTransaction.component}
          />
          <Route
            path={RoutePath.profileDetail.path}
            element={RoutePath.profileDetail.component}
          />
          <Route
            path={RoutePath.editProfileDetail.path}
            element={RoutePath.editProfileDetail.component}
          />
          <Route
            path={RoutePath.requestManagement.path}
            element={RoutePath.requestManagement.component}
          />
          <Route
            path={RoutePath.documentManagement.path}
            element={RoutePath.documentManagement.component}
          />
          <Route
            path={RoutePath.contactUs.path}
            element={RoutePath.contactUs.component}
          />
          <Route
            path={RoutePath.termsAndConditions.path}
            element={RoutePath.termsAndConditions.component}
          />
          <Route
            path={RoutePath.privacyAndPolicy.path}
            element={RoutePath.privacyAndPolicy.component}
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default RouteStack;
