/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import IMAGES from "../../utils/constants/images";
import { useAppContext } from "../../context/authContext";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "../../routes/routePath";
import CustomAlert from "./alert";
import { headersArray } from "../../utils/seeds/headersArray";
import { useGetCompanyProfile } from "../../react-query/hooks/profile";
import { useGetRequests } from "../../react-query/hooks/requests";

const Header = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const { isLoggedOut } = useAppContext();
  const [isActive, setIsActive] = React.useState(false);

  // Hook to fetch company profile
  const { data } = useGetCompanyProfile();
  const { data: requestData } = useGetRequests({
    limit: null,
    page: null,
    status: null,
  });

  const pendingRequest = requestData?.data?.requestList?.filter(
    (item: any) => item?.status === 0
  );

  const profileData = data?.data;

  function handleNavigate(path: string) {
    navigate(path);
  }

  function handleLogout() {
    CustomAlert({
      title: "Are you sure want to logout?",
      confirmButtonText: "Logout",
      icon: "warning",
      onConfirm: () => {
        isLoggedOut();
        handleNavigate(RoutePath.subscriptionPlan.path);
      },
    });
  }

  return (
    <header className="shadow-sm p-4 bg-body position-sticky top-0 rounded z-3">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="header-wrapper">
              <div className="header-logo-nav-blk">
                <div className="logo">
                  <a
                    onClick={() =>
                      handleNavigate(RoutePath.subscriptionPlan.path)
                    }
                  >
                    <img src={IMAGES.newLogo} alt="..." />
                  </a>
                </div>
              </div>
              <div className={`header-nav ${isActive ? "show" : ""}`}>
                <ul className="d-flex list-unstyled gap-5 header-list m-0">
                  {headersArray?.map((items, index) => {
                    return (
                      <li
                        onClick={() => {
                          handleNavigate(items?.path);
                          setIsActive(false);
                        }}
                        key={index}
                      >
                        <a
                          className={
                            items?.path === location?.pathname
                              ? "nav-link active"
                              : "nav-link"
                          }
                        >
                          {items?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="header-btn-blk">
                <div className="header-profile-blk d-flex justify-content-end align-items-center gap-4">
                  <div
                    style={{
                      position: "relative",
                    }}
                    onClick={() =>
                      pendingRequest.length > 0 &&
                      handleNavigate(RoutePath.requestManagement.path)
                    }
                  >
                    <img
                      src={IMAGES.notificationIcon}
                      width={18}
                      height={18}
                      alt="err"
                    />
                    {pendingRequest?.length > 0 && (
                      <span className="top-icon-blk">
                        {pendingRequest.length}
                      </span>
                    )}
                  </div>
                  <div className="header-profile-img">
                    {profileData?.profileImage && (
                      <img
                        onClick={() => {
                          navigate(RoutePath.profileDetail.path, {
                            state: {
                              data: profileData,
                            },
                          });
                          setIsActive(false);
                        }}
                        src={
                          profileData?.profileImage
                            ? profileData?.profileImage
                            : IMAGES.profileLogo
                        }
                        alt="..."
                      />
                    )}
                  </div>
                  <button className="logout" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
                <button
                  className={`togglebtn d-xl-none ${isActive ? "active" : ""}`}
                  onClick={() => setIsActive(!isActive)}
                >
                  <span className="top-bar"></span>
                  <span className="middle-bar"></span>
                  <span className="bottom-bar"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
